import React from "react";
import Header from "./header";
import Footer from "./footer";
import "./styles.scss";

const Layout = (props) => {
  const style = props.style;
  return (
    <div style={style}>
      <Header />
      {props.children}
      <Footer />
    </div>
  );
};

export default Layout;
