import React from "react";
import { useIntl, Link } from "gatsby-plugin-intl";
import CookieConsent from "react-cookie-consent";

import logo from "../assets/imgs/logo-black.svg";
import safe from "../assets/imgs/safe.png";
import { ReactComponent as Mail } from "../assets/imgs/mail.svg";
import { ReactComponent as Phone } from "../assets/imgs/phone.svg";
import { ReactComponent as Marker } from "../assets/imgs/marker.svg";
import { ReactComponent as Arrow } from "../assets/imgs/arrow.svg";
import { ReactComponent as Facebook } from "../assets/imgs/facebook.svg";
import { ReactComponent as Google } from "../assets/imgs/google.svg";
import { ReactComponent as Heart } from "../assets/imgs/heart.svg";

const Footer = () => {
  const intl = useIntl();

  return (
    <>
      <CookieConsent
        buttonText="OK"
        style={{ fontFamily: "Kuro,sans-serif" }}
        buttonStyle={{ background: "#fca311" }}
        acceptOnScroll={true}
        acceptOnScrollPercentage={30}
      >
        {intl.formatMessage({ id: "footer.cookie-consent" })}
      </CookieConsent>
      <footer>
        <div className="row content section">
          <div className="col">
            <div className="logo-black">
              <img src={logo} alt="logo" />
            </div>
            <a
              className="email"
              href="mailto:jelena.jeremic.pavletic@gmail.com"
            >
              <Mail />
              jelena.jeremic.pavletic@gmail.com
            </a>
            <a className="tel" href="tel:00385959010526">
              <Phone />
              +385(0)959010526
            </a>
            <a
              href="https://goo.gl/maps/BCgK6HC8SrpRUjvC7"
              target="_blank"
              rel="noreferrer"
            >
              <Marker />
              Antonija Bronzina 6, 52210 Rovinj - Hrvatska
            </a>
          </div>
          <div className="col" style={{ alignItems: "center" }}>
            <Link to="/kontakt" className="kontakt-button">
              <h2>{intl.formatMessage({ id: "footer.kontaktirajte-nas" })}</h2>
              <Arrow />
            </Link>
            <div className="external">
              <a href="https://www.facebook.com/rovinj.erik/">
                <Facebook />
              </a>
              <a href="https://www.google.com/travel/hotels/entity/CgoImpD21a6F_dpTEAE/overview?g2lb=2502548%2C4258168%2C4260007%2C4270442%2C4274032%2C4291318%2C4305595%2C4306835%2C4317915%2C4322823%2C4326765%2C4328159%2C4329288%2C4366684%2C4367954%2C4373848%2C4381263%2C4385383%2C4386665%2C4390419%2C4395397%2C4395402%2C4395590%2C4397705%2C4270859%2C4284970%2C4291517%2C4316256%2C4356900&hl=hr&gl=hr&un=1&rp=EJqQ9tWuhf3aUzgCQABIAXizuuvEt5urwhk&ictx=1&sa=X&utm_campaign=sharing&utm_medium=link&utm_source=htls&hrf=IgNIUksqFgoHCOQPEAYYGBIHCOQPEAYYGRgBKACCASUweDQ3N2NiZjYyMWJkZTJmZjc6MHg1M2I1ZjQyYWVhYmQ4ODFhmgEnGiUweDQ3N2NiZjYyMWJkZTJmZjc6MHg1M2I1ZjQyYWVhYmQ4ODFh">
                <Google />
              </a>
            </div>
          </div>
        </div>
        <div className="extra-links content">
          <a href="/sitemap.xml">
            {intl.formatMessage({ id: "footer.sitemap" })}
          </a>
          <Link to="/pravila-o-privatnosti">
            {intl.formatMessage({ id: "footer.privacy" })}
          </Link>
          <Link to="/obavijest-o-kolacicima">
            {intl.formatMessage({ id: "footer.cookie-notice" })}
          </Link>
        </div>
        <div className="madebybang">
          <a href="https://bang.agency">
            <p>
              Made with <Heart /> by bang.agency
            </p>
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
