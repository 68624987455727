import React from "react";
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl";

const languageName = {
  en: "EN",
  hr: "HR",
  it: "IT",
  de: "DE",
};
const handleLanguageChange = (e) => {
  changeLocale(e.target.value);
};

const Language = () => {
  return (
    <div className="language-selector">
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <select onChange={handleLanguageChange} value={currentLocale}>
            {languages.map((language) => (
              <option
                key={language}
                value={language}
                style={{
                  color: currentLocale === language ? `black` : `grey`,
                  margin: 10,
                  textDecoration: `underline`,
                  cursor: `pointer`,
                }}
              >
                {languageName[language]}
              </option>
            ))}
          </select>
        )}
      </IntlContextConsumer>
    </div>
  );
};

export default Language;
